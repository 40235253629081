import type { IssueType } from "$bindings/api/IssueType"
import {
    bug_fill,
    circle,
    cursor_fill,
    plus_circle_fill,
    question_circle_fill,
} from "@timephy/tui-icons-svelte"
import type { IconType } from "svelte-awesome/components/Icon.svelte"

// IssueType as array
export const IssueTypes = ["General", "Usability", "Feature", "Help", "Bug"] as const

export const IssueTitle: { [type in IssueType]: string } = {
    General: "General",
    Usability: "Usability",
    Feature: "Feature Request",
    Help: "Support Request",
    Bug: "Bug Report",
}

export const IssueDescription: { [type in IssueType]: string } = {
    General: "General comment or opinion",
    Usability: "Something could be improved",
    Feature: "Suggest new functionality",
    Help: "I need help",
    Bug: "Something is wrong",
}

export const IssuePrompt: { [type in IssueType]: string } = {
    General: "Please tell us what you think.",
    Usability:
        "Please describe what feature you think should be improved, explain how and why the current behaviour should be changed.",
    Feature:
        "Please describe what new functionality you think would be useful or fun. What should the feature do? How would you like to use it?",
    Help: "Please describe what problem you are facing.\nWe will be glad to help you.",
    Bug: "Please describe what feature is broken and explain where and how you found the problem.",
}

export const IssueIcon: { [type in IssueType]: IconType } = {
    General: circle,
    Usability: cursor_fill,
    Feature: plus_circle_fill,
    Help: question_circle_fill,
    Bug: bug_fill,
}
